$(document).ready(function() {
	
	// youtube Videos
	// $('.popup-youtube').magnificPopup({        
	// 	type: 'iframe',        
	// 	mainClass: 'mfp-fade',        
	// 	removalDelay: 100,        
	// 	preloader: true,        
	// 	fixedContentPos: false    
	// });

	$(".menu-button").click(function(){
		$("body").addClass("show-menu");
	  	$("body").addClass("noScroll");
	});

	
	$(".content-wrap").click(function(){
		$("body").removeClass("show-menu");
		$("body").removeClass("noScroll");
	});

  //Magnific popup Gallery
    $(".ImageGalleryPopup").magnificPopup({
      type: 'image',
      mainClass: 'mfp-with-zoom',
      gallery: {
        enabled: true
      },
      zoom: {
        enabled: true,
        duration: 300,
        easing: 'ease-in-out',
        opener: function(openerElement) {
          // return openerElement.is('img') ? openerElement : openerElement.find('img');
          return openerElement ;
        }
      }
    });

    $('.open-popup').magnificPopup({
      type: 'inline',
      midClick: true,
      mainClass: 'mfp-fade'
    });

})

$('.grid').masonry({
  itemSelector: '.grid-item',
  columnWidth: 277,
  horizontalOrder: true
});

jQuery(document).ready(function($) {
    $('.FullImageSlider').slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [{
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
           breakpoint: 400,
           settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
           }
        }]
    });
});

var channedId = 'UCIBQmCCxU_BSQYp29uwUwbA'

$(document).ready(function() {
  $.get(
    "https://www.googleapis.com/youtube/v3/channels", {
      part: 'contentDetails',
      id: channedId,
      key: 'AIzaSyD4GGr-zfbBPc6wpivrEd-DzWcYJFnl3H0'},
      function(data) {
        console.log(data, "data")
        $.each(data.items, function(i, item) {
          console.log(item,"item");
          let pid = item.contentDetails.relatedPlaylists.uploads;
          console.log(pid, "pid")
          getVids(pid);
        })
      }
  );
  function getVids(pid) {
    $.get(
      "https://www.googleapis.com/youtube/v3/playlistItems", {
        part: 'snippet',
        maxResults: 6,
        playlistId: pid,
        key: 'AIzaSyD4GGr-zfbBPc6wpivrEd-DzWcYJFnl3H0'},
        function(data) {
          var output;
          $.each(data.items, function(i, item) {
            console.log(item," video item");
            let videoTitle = item.snippet.title;
            let thumbnail = item.snippet.thumbnails.high.url
            let videoId = item.snippet.resourceId.videoId
            let pageUrl = window.location.url
            output = `<div class="col-6 col-md-4">
                        <a href="https://www.youtube.com/watch?v=${videoId}" class="popup-youtube">
                          <div class="YoutubeThumbnailBlock">
                            <span class="playBtn"><img src="${stylesheet_directory_uri}/assets/img/play.png"></span>
                            <img src="${thumbnail}">
                            <h6>${videoTitle}</h6>
                          </div>
                        </a>
                      </div>`
            $('#youtubeList').append(output);

            $('.popup-youtube').magnificPopup({        
              type: 'iframe',        
              mainClass: 'mfp-fade',        
              removalDelay: 100,        
              preloader: true,        
              fixedContentPos: false    
            });
          })
        }
    );
  }
})

const hello = (name) => {
  return `hello ${name}`;
};
console.log(hello);

